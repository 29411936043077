import { jsPDF } from 'jspdf';
import { toReadableFormat } from './dateFormat';
import * as htmlToImage from 'html-to-image';

export const colors = {
  bgGrey: '#D1D5DB',
  bgRed: '#F86464',
  bgYellow: '#FBDA67',
  bgGreen: '#2BCC57',
  bgBlue: '#0090F9',
  bgPurple: '#2A2A8B',
  fntWhite: '#FFFFFF',
  fntBlack: '#333333',
  bgLightPurple: '#7270FE',
};

export interface PdfProps {
  pdfDoc: jsPDF;
  pageWidth: number;
  pageHeight: number;
  pdfMargin: number;
  pdfPadding: number;
  posX: number;
  posY: number;
}

export const pdfNewPage = (
  pdfDoc: jsPDF,
  pageHeight: number,
  pdfMargin: number,
  pdfPadding: number,
  posX: number,
  posY: number,
  elHeight: number
) => {
  if (posY + elHeight > pageHeight) {
    pdfDoc.addPage();
    posX = pdfMargin;
    posY = pdfPadding + 5;
    pdfDoc.line(posX, posY, pdfDoc.internal.pageSize.width - pdfMargin, posY);
  }
  return [posX, posY + 5];
};

export const adjustWidgetElementHeightWidth = (widgetElement: HTMLElement, pageWidth: number, pdfMargin: number) => {
  let elHeight = widgetElement.offsetHeight;
  let elWidth = widgetElement.offsetWidth;

  if (elWidth > pageWidth) {
    let ratio = pageWidth / elWidth;
    elHeight = elHeight * ratio - pdfMargin * 2;
    elWidth = elWidth * ratio - pdfMargin * 2;
  }
  return [elHeight, elWidth];
};

export const getPdfTextWidth = (text: string, pdf: jsPDF, fontSize: number) => {
  return (pdf.getStringUnitWidth(`${text}` as string) * fontSize) / pdf.internal.scaleFactor;
};

export const drawAutotableRowBorders = (data: any, pdf: jsPDF) => {
  const { table, row, column, cell } = data;

  // Draw top border for all cells in the row except the first one
  if (row.section === 'body') {
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.1);
    pdf.line(cell.x, cell.y, cell.x + cell.width, cell.y);
  }

  // Draw bottom border for all cells in the row
  pdf.setDrawColor(0);
  pdf.setLineWidth(0.1);
  pdf.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height);

  // Draw left border for the first cell in the row
  if (column.index === 0) {
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.1);
    pdf.line(cell.x, cell.y, cell.x, cell.y + cell.height);
  }

  // Draw right border for the last cell in the row
  if (column.index === table.columns.length - 1) {
    pdf.setDrawColor(0);
    pdf.setLineWidth(0.1);
    pdf.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height);
  }
};

export const drawColumnBorders = (data: any, pdfDoc: jsPDF, columns: number[]) => {
  const { row, column, cell } = data;
  if (columns.includes(column.index) && row.section === 'body') {
    pdfDoc.setDrawColor(0);
    pdfDoc.setLineWidth(0.2);
    pdfDoc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height);
  }
};

const getDistrictWideScoreChipStyle = (score: number) => {
  let bgColor = colors.bgGrey;
  let fntColor = colors.fntBlack;
  if (0 < score && score < 1.5) {
    bgColor = colors.bgRed;
    fntColor = colors.fntWhite;
  } else if (1.5 <= score && score < 2.5) {
    bgColor = colors.bgYellow;
    fntColor = colors.fntBlack;
  } else if (2.5 <= score && score < 3.5) {
    bgColor = colors.bgGreen;
    fntColor = colors.fntWhite;
  } else if (3.5 <= score && score < 4.5) {
    bgColor = colors.bgBlue;
    fntColor = colors.fntWhite;
  } else if (4.5 <= score && score <= 5) {
    bgColor = colors.bgLightPurple;
    fntColor = colors.fntWhite;
  }

  return { textColor: fntColor, fillColor: bgColor, text: score.toString(), fontSize: 8 };
};

export const drawAutotableCellChipWithText = (data: any, pdf: jsPDF, cols: number[]) => {
  const { row, column, cell } = data;

  // Skip the header row
  if (row.section === 'head' && row.index === 0) return;

  // Check if the current cell is in the specified column
  if (cols.includes(column.index)) {
    const badgeValue = cell.raw;

    // Customize badge styles
    const badgeStyles = getDistrictWideScoreChipStyle(badgeValue);

    // Draw the badge
    pdf.setFillColor(badgeStyles.fillColor);
    const badgeWidth = getPdfTextWidth('xxxxxx', pdf, badgeStyles.fontSize);
    const badgeX = cell.x + (cell.width - badgeWidth) / 2;
    const badgeY = cell.y + 1;
    pdf.roundedRect(badgeX, badgeY, badgeWidth, cell.height - 4, 4, 4, 'F');

    // Draw the badge text
    pdf.setTextColor(badgeStyles.textColor);
    pdf.setFontSize(badgeStyles.fontSize);
    const textWidth = getPdfTextWidth(`${badgeValue}`, pdf, badgeStyles.fontSize);
    const textX = cell.x + (cell.width - textWidth) / 2;
    const textY = cell.y + cell.height / 2 + 1;
    // this may cause 'double vision' - if we see a report with the problem again
    // start here.
    pdf.text(`${badgeValue}`, textX, textY);
  }
};

export const drawSmallAutotableCellChipsWithText = (data: any, pdf: jsPDF, cols: number[], adjustY: number = 1) => {
  const { row, column, cell } = data;

  // Skip the header row
  if (row.section === 'head') return;

  // Check if the current cell is in the specified column
  if (!cols.includes(column.index)) {
    return;
  }

  const badgeValue = cell.raw;

  // Customize badge styles
  const badgeStyles = getDistrictWideScoreChipStyle(badgeValue);

  // Draw the badge
  pdf.setFillColor(badgeStyles.fillColor);
  const badgeWidth = getPdfTextWidth('xxxx', pdf, badgeStyles.fontSize);
  const badgeX = cell.x + (cell.width - badgeWidth) / 2;
  const badgeY = cell.y + adjustY;
  pdf.roundedRect(badgeX, badgeY, badgeWidth, cell.height - 6, 4, 4, 'F');

  // Draw the badge text
  pdf.setTextColor(badgeStyles.textColor);
  pdf.setFontSize(badgeStyles.fontSize);
  const textWidth = getPdfTextWidth(`${badgeValue}`, pdf, badgeStyles.fontSize);
  const textX = cell.x + (cell.width - textWidth) / 2;
  const textY = cell.y + cell.height / 2 + adjustY;
  if (badgeValue == 0) {
    drawHyphen(pdf, textX, textY - 3, textX + 5, textY - 3, 1, '#fff');
  }
};

interface DrawChipWithTextProps {
  pdf: jsPDF;
  text: string;
  fontSize: number;
  x: number;
  y: number;
  rx: number;
  ry: number;
  textWidth: number;
  height: number;
  backgroundColor: string;
  textColor: string;
}

export const drawChipWithText = ({
  pdf,
  text,
  fontSize,
  x,
  y,
  rx,
  ry,
  textWidth,
  height,
  backgroundColor,
  textColor,
}: DrawChipWithTextProps) => {
  // Draw the rectangular chip
  pdf.setFillColor(backgroundColor);
  pdf.roundedRect(x, y, textWidth + 2 * rx, height, rx, ry, 'F');

  // Add text inside the chip
  pdf.setTextColor(textColor);
  pdf.setFontSize(fontSize);
  pdf.text(text, x + rx + textWidth / 2, y + height / 2, { align: 'center', baseline: 'middle' });
};

export const extractTitle = (
  pdfDoc: jsPDF,
  posX: number,
  posY: number,
  pdfPadding: number,
  pdfMargin: number,
  header: string,
  title: string,
  lastSnowflakeUpdateTimeData: any
): [number, number, string] => {
  pdfDoc.setFontSize(8);
  pdfDoc.text(`${header}`, posX, posY);
  const date = new Date();
  const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  pdfDoc.text(dateString, pdfDoc.internal.pageSize.width - pdfMargin, posY, { align: 'right' });
  posY += 5;
  pdfDoc.line(posX, posY, pdfDoc.internal.pageSize.width - pdfMargin, posY);
  posY += 2 * pdfPadding;
  pdfDoc.setFontSize(18);
  pdfDoc.text(title, posX, posY);
  posY += pdfPadding + 5;
  //Add the last updated time
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(141, 141, 141);
  const lastUpdated =
    'Last Updated: ' +
    toReadableFormat(
      lastSnowflakeUpdateTimeData,
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'UTC',
      },
      'en-US'
    ).replace('at', '') +
    ' UTC';
  pdfDoc.text(lastUpdated, posX, posY);
  posY += pdfPadding;
  pdfDoc.setTextColor(51, 51, 51);
  return [posX, posY, dateString];
};

export const extractWidget = async (
  pdfDoc: jsPDF,
  posX: number,
  posY: number,
  pdfPadding: number,
  widgetElementId: any,
  pageWidth: number,
  pdfMargin: number,
  adjustHeight: number,
  adjustWidth: number,
  removeElementId?: any[]
) => {
  const widgetElement = document.getElementById(widgetElementId) as HTMLElement;
  let elHeight: number;
  let elWidth: number;

  // Hide or remove specified sub-elements if excludeElementSelectors are provided
  const elementsToHide: HTMLElement[] = [];
  if (removeElementId) {
    removeElementId.forEach((selector) => {
      const elements = widgetElement.querySelectorAll(selector) as NodeListOf<HTMLElement>;
      elements.forEach((element) => {
        element.style.display = 'none';
        elementsToHide.push(element);
      });
    });
  }
  const png = await htmlToImage.toPng(widgetElement);
  // Restore hidden elements
  elementsToHide.forEach((element) => {
    element.style.display = '';
  });
  [elHeight, elWidth] = adjustWidgetElementHeightWidth(widgetElement, pageWidth, pdfMargin);
  pdfDoc.addImage(png, 'PNG', posX, posY, elWidth + adjustWidth, elHeight + adjustHeight, widgetElementId, 'NONE');
  return [posX, posY, elHeight, elWidth];
};
export const extractAdustedHtWthWidget = async (
  pdfDoc: jsPDF,
  posX: number,
  posY: number,
  pdfPadding: number,
  widgetElementId: any,
  pageWidth: number,
  pdfMargin: number,
  adjustHeight: number,
  adjustWidth: number,
  removeElementId?: any[]
) => {
  const widgetElement = document.getElementById(widgetElementId) as HTMLElement;
  let elHeight = widgetElement.offsetHeight;
  let elWidth = widgetElement.offsetWidth;

  // Hide or remove specified sub-elements if excludeElementSelectors are provided
  const elementsToHide: HTMLElement[] = [];
  if (removeElementId) {
    removeElementId.forEach((selector) => {
      const elements = widgetElement.querySelectorAll(selector) as NodeListOf<HTMLElement>;
      elements.forEach((element) => {
        element.style.display = 'none';
        elementsToHide.push(element);
      });
    });
  }
  const png = await htmlToImage.toPng(widgetElement, { width: elWidth, height: elHeight });
  // Restore hidden elements
  elementsToHide.forEach((element) => {
    element.style.display = '';
  });
  [elHeight, elWidth] = adjustWidgetElementHeightWidth(widgetElement, pageWidth, pdfMargin);
  pdfDoc.addImage(png, 'PNG', posX, posY, elWidth + adjustWidth, elHeight + adjustHeight, widgetElementId, 'NONE');
  return [posX, posY, elHeight, elWidth];
};

export const renderChips = (
  pdf: jsPDF,
  posX: number,
  posY: number,
  pdfPadding: number,
  chips: any[],
  defaultChipProps: any,
  fontSize: number
) => {
  let textWidth = 0;
  chips.forEach((text, index) => {
    let textWidthPrev = textWidth;
    textWidth = getPdfTextWidth(text, pdf, fontSize);
    if (index === 0) {
      drawChipWithText({
        ...defaultChipProps,
        text,
        textWidth,
        backgroundColor: colors.bgPurple,
        textColor: colors.fntWhite,
      });
    } else {
      posX = posX + textWidthPrev + 2 * defaultChipProps.rx + pdfPadding / 3;
      drawChipWithText({ ...defaultChipProps, text, textWidth, x: posX });
    }
  });
};

export const drawDownArrow = (pdfDoc: jsPDF, x: number, y: number, size: number) => {
  pdfDoc.setFillColor('#4B5563'); // Set fill color to black
  pdfDoc.triangle(x, y, x + size * 2, y, x + size, y + size, 'F');
};

export const drawHyphen = (
  pdfDoc: jsPDF,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  thickness: number,
  color: string = '#4B5563'
) => {
  pdfDoc.setFillColor(color); // Set fill color to black
  pdfDoc.rect(x1, y1 - thickness / 2, x2 - x1, thickness, 'F'); // Draw rectangle
};
export const drawUpArrow = (pdfDoc: jsPDF, x: number, y: number, size: number) => {
  pdfDoc.setFillColor('#4B5563'); // Set fill color to black
  pdfDoc.triangle(x, y + size, x + size * 2, y + size, x + size, y, 'F');
};

export const renderLegend = (pdf: jsPDF, posX: number, posY: number, legendData: any[]) => {
  let textWidth = 0;
  legendData.forEach((item) => {
    const fontSize = 6;
    const circleRadius = 2.5;
    textWidth = getPdfTextWidth(item.skillTitle, pdf, fontSize);
    pdf.setFillColor(item.color);
    pdf.circle(posX + circleRadius * 2, posY - circleRadius, circleRadius, 'F');
    pdf.setFontSize(fontSize);
    pdf.setTextColor('#333');
    pdf.text(item.skillTitle, posX + circleRadius * 4, posY - 1);
    posX += textWidth + circleRadius * 6;
  });
};

export const renderText = (pdf: jsPDF, posX: number, posY: number, text: string) => {
  const fontSize = 8;
  pdf.setFontSize(fontSize);
  pdf.setTextColor('#333');
  pdf.text(text, posX, posY);
};

export const drawAutotableCellIcons = (data: any, jsPDF1: jsPDF, columns: number[]) => {
  const { row, column, cell } = data;
  const textX = cell.x + (data.cell.width - 13) / 2;
  const textY = cell.y + data.cell.height / 3;
  // Skip the header row
  if (row.section === 'head') return;
  // Check if the current cell is in the specified column
  if (columns.includes(column.index)) {
    if (cell.raw === '') {
      drawHyphen(jsPDF1, textX + 2, textY + 3, textX + 8, textY + 3, 1);
    } else if (cell.raw === 'up') {
      drawUpArrow(jsPDF1, textX + 2, textY + 2, 4);
    } else if (cell.raw === 'down') {
      drawDownArrow(jsPDF1, textX + 2, textY + 2, 4);
    }
  }
};

export const drawAutotableCellEmptyIcons = (data: any, jsPDF1: jsPDF, columns: number[]) => {
  const { row, column, cell } = data;
  const textX = cell.x + (data.cell.width - 13) / 2;
  const textY = cell.y + data.cell.height / 3;
  // Skip the header row
  if (row.section === 'head') return;
  // Check if the current cell is in the specified column
  if (columns.includes(column.index)) {
    if (cell.raw === '') {
      drawHyphen(jsPDF1, textX + 2, textY + 3, textX + 8, textY + 3, 1);
    }
  }
};
